import { EVENT_NAME, EVENT_NAME_STG } from '~~/const/gtm-events';

type EventKey = keyof typeof EVENT_NAME | keyof typeof EVENT_NAME_STG;

declare global {
  interface Window {
    dataLayer: unknown[];
  }
}

export const useGTM = defineStore('gtm', {
  getters: {
    isProd: () =>
      process.env.ENVIRONMENT === 'production' ||
      (typeof location != 'undefined' && location.hostname.includes('myvalue.id')),
    dataLayer: () => window.dataLayer,
  },
  actions: {
    pushEvent<T = any>(key: EventKey, params: T) {
      const event = !this.isProd ? EVENT_NAME_STG[key] : EVENT_NAME[key];
      return this.dataLayer?.push({ event, [event]: params });
    },

    clickLogin(state: unknown) {
      const event = !this.isProd ? EVENT_NAME_STG.introLoginClick : EVENT_NAME.introLoginClick;
      this.dataLayer?.push({ event, [event]: state });
    },

    openLoginPage(state: unknown) {
      const event = !this.isProd ? EVENT_NAME_STG.introLoginPage : EVENT_NAME.introLoginPage;
      this.dataLayer?.push({ event, [event]: state });
    },

    openRegisterPage(state: unknown) {
      const viewRegister = !this.isProd ? EVENT_NAME_STG.viewRegister : EVENT_NAME.viewRegister;
      const viewRegisterReferral = !this.isProd
        ? EVENT_NAME_STG.viewRegisterReferral
        : EVENT_NAME.viewRegisterReferral;
      const event = state === 'isReferral' ? viewRegisterReferral : viewRegister;
      this.dataLayer?.push({ event, [event]: state });
    },

    submitSuccessRegister(state: unknown) {
      const submitRegister = !this.isProd ? EVENT_NAME_STG.submitRegister : EVENT_NAME.submitRegister;
      const submitRegisterReferral = !this.isProd
        ? EVENT_NAME_STG.submitRegisterReferral
        : EVENT_NAME.submitRegisterReferral;
      const event = state === 'isReferral' ? submitRegisterReferral : submitRegister;
      this.dataLayer?.push({ event, [event]: state });
    },

    clickForgotPassword(state: unknown) {
      const event = !this.isProd
        ? EVENT_NAME_STG.introForgetPasswordClick
        : EVENT_NAME.introForgetPasswordClick;
      this.dataLayer?.push({ event, [event]: state });
    },

    openMyValueWrap(state: unknown) {
      const event = !this.isProd ? EVENT_NAME_STG.viewMyValueWrap : EVENT_NAME.viewMyValueWrap;
      return this.dataLayer?.push({ event, [event]: state });
    },

    myValueWrapDownload(state: unknown) {
      const event = !this.isProd ? EVENT_NAME_STG.downloadMyValueWrap : EVENT_NAME.downloadMyValueWrap;
      return this.dataLayer?.push({ event, myvaluewrap_download: `${state}` });
    },
  },
});
