import { reactive } from "vue";
import { defineNuxtPlugin } from "#imports";
const plugin = defineNuxtPlugin(() => {
  return {
    provide: reactive({
      ssrClientHints: {
        firstRequest: false,
        prefersColorSchemeAvailable: false,
        prefersReducedMotionAvailable: false,
        viewportHeightAvailable: false,
        viewportWidthAvailable: false
      }
    })
  };
});
export default plugin;
