import { ofetch } from 'ofetch';

export default defineNuxtPlugin({
  name: 'ofetch-intercept',
  setup() {
    globalThis.$fetch = ofetch.create({
      onResponseError({ error, response, options }) {
        const app = useNuxtApp();
        const auth = useAuth();

        const data = response._data as APIErrorData;
        const method = options.method?.toUpperCase() ?? 'GET';
        const endpoint = response.url;

        app.$sentry?.withScope((scope) =>
          auth
            .sentryLogUser(scope)
            .setTags({ method, endpoint })
            .setTransactionName(`[${method}] ${endpoint}`)
            .captureException(error ?? data)
        );
      },
    });
  },
});
