type UnwrappedPromise<T, E> = Promise<{ data: T; error?: never } | { data?: never; error: E }>;

/**
 * Unwraps a promise and returns the result along with any potential error.
 *
 * @template T - The type of the data returned by the promise.
 * @template E - The type of the error returned by the promise.
 */
async function unwrapPromise<T = any, E = APIErrorData & { res?: Response }>(
  promise: Promise<T>
): UnwrappedPromise<T, E> {
  try {
    const data = await promise;
    return { data };
  } catch (e: any) {
    const error = e.data as E;
    // @ts-ignore
    if (error) error.res = e.response;
    return { error };
  }
}

export default unwrapPromise;
