export const EVENT_NAME = {
  introLoginPage: 'introLoginPage' as const,
  introLoginClick: 'introLoginClick' as const,
  viewRegister: 'viewRegister' as const,
  viewRegisterReferral: 'viewRegisterReferral' as const,
  submitRegister: 'submitRegister' as const,
  submitRegisterReferral: 'submitRegisterReferral' as const,
  introForgetPasswordClick: 'introForgetPasswordClick' as const,
  viewMyValueWrap: 'viewMyValueWrap' as const,
  introAddCardClick: 'introAddCardClick' as const,
  introMyCardView: 'introMyCardView' as const,
  introMyPointView: 'introMyPointView' as const,
  downloadMyValueWrap: 'downloadMyValueWrap' as const,
};

export const EVENT_NAME_STG = {
  introLoginPage: 'introLoginPageStaging' as const,
  introLoginClick: 'introLoginClickStaging' as const,
  viewRegister: 'viewRegisterStaging' as const,
  viewRegisterReferral: 'viewRegisterReferralStaging' as const,
  submitRegister: 'submitRegisterStaging' as const,
  submitRegisterReferral: 'submitRegisterReferralStaging' as const,
  introForgetPasswordClick: 'introForgetPasswordClickStaging' as const,
  viewMyValueWrap: 'viewMyValueWrapStaging' as const,
  introAddCardClick: 'introAddCardClickStaging' as const,
  introMyCardView: 'introMyCardViewStaging' as const,
  introMyPointView: 'introMyPointViewStaging' as const,
  downloadMyValueWrap: 'downloadMyValueWrapStaging' as const,
};
