import type { FetchError } from 'ofetch';
import type { UseFetchOptions } from '#app';

type FetchOptions = NonNullable<Parameters<typeof $fetch>[1]>;

export function statefulFetch<T = unknown>(url: string, opts = {} as FetchOptions) {
  populateHeader(opts);
  return $fetch<T>(url, opts);
}

export function useStatefulFetch<T = unknown, E = FetchError>(
  url: string | Ref<string> | ComputedRef<string>,
  opts = {} as UseFetchOptions<T>
) {
  populateHeader(opts as UseFetchOptions<unknown>);
  return useFetch<T, E>(url, opts as any);
}

function populateHeader<T extends FetchOptions | UseFetchOptions<unknown>>(opts: T) {
  const auth = useAuth();
  const cookie = useCookie(AUTH.accessTokenKey);

  const token = auth.tokens?.at || cookie.value || '';
  const headers = (opts.headers ??= {});

  // @ts-expect-error
  headers['Authorization'] = `Bearer ${token}`;

  return opts;
}
