export default defineNuxtPlugin({
  name: 'sentry-client',
  async setup(app) {
    const dsn = app.$config.public.sentryDsn;
    const router = useRouter();
    const sentry = await import('@sentry/vue');

    if (dsn)
      sentry.init({
        dsn,
        app: app.vueApp,
        integrations: [sentry.browserTracingIntegration({ router })],
        tracesSampleRate: 0.7,
      });

    return { provide: { sentry } };
  },
});
