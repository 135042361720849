<script setup lang="ts">
import '@fontsource-variable/nunito-sans';

import { Toaster } from 'vue-sonner';

useHead({
  link: [{ rel: 'icon', type: 'image/png', href: '/favicon.png' }],
  titleTemplate(titleChunk) {
    const base = 'MyValue SSO Service';
    return titleChunk ? `${titleChunk} - ${base}` : base;
  },
});

const auth = useAuth();
const route = useRoute();

onMounted(() => {
  const { clarity } = useScriptClarity();

  // Remove Bearer from URL (if exists)
  const { bearer, ...restQuery } = route.query;
  if (bearer) navigateTo({ query: restQuery, replace: true });

  // Send signal to Parent that the SSO view is ready
  parent.postMessage('READY', '*');
  if ('BroadcastChannel' in window) {
    const chan = new BroadcastChannel('ValueID');
    chan.postMessage('READY');
    chan.close();
  }

  // Clarity Initialization
  if (auth.user) {
    const identify = auth.user.kgValueID || auth.user.email;
    clarity('identify', identify, undefined, route.path, auth.fullName);
  }
});
</script>

<template>
  <v-app>
    <NuxtLoadingIndicator color="rgb(var(--v-theme-primary))" />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <ClientOnly>
      <Toaster position="top-center" />
    </ClientOnly>
  </v-app>
</template>
