import { joinURL, withQuery } from 'ufo';

interface URLOptions {
  /** Enable to fetch data via frontend proxy to append additional
   *  body data or headers that cannot be exposed to the client side
   */
  proxy?: {
    /**
     * Format: [ClientId_FieldName, ClientSecret_FieldName, RedirectUri_FieldName]
     */
    format: [string, string, string?];
  };
}

const url = (path: string, opt = {} as URLOptions) => {
  const config = useRuntimeConfig();

  if (opt.proxy)
    return withQuery(joinURL(`${config.public.baseUrl}/webapi/sso`, path), {
      format: opt.proxy.format.join(','),
    });

  return joinURL(config.public.ssoBaseUrl, path);
};

const SSO = {
  v1: {
    get getUserData() {
      return url('/v1/user');
    },
    get checkIfUserExist() {
      return url('/v1/user/check');
    },
    /** [PUT] Payload: `oldPassword` `newPassword` */
    get changeUserPassword() {
      return url('/v1/user/password');
    },
    /** [PUT] Payload: `password` */
    get checkUserPassword() {
      return url('/v1/user/password/check');
    },
    /** [POST] FormData: `photo` */
    get changeUserPhoto() {
      return url('/v1/user/photo');
    },
    get userCardList() {
      return url('/v1/user/cards');
    },
    /** [PUT] Payload: `email` `code` | Result: `resend_time` `status` `email` `phone` */
    get changeUserEmail() {
      return url('/v1/user/email');
    },
    /** [POST] Payload: `email` `template` | Result: `resend_time` `status` `email` `phone` */
    get reqChangeUserEmail() {
      return url('/v1/user/email/otp');
    },
    /** [PUT] Payload: `phone` `code` | Result: `resend_time` `status` `email` `phone` */
    get changeUserPhone() {
      return url('/v1/user/phone');
    },
    /** [POST] Payload: `phone` `template` | Result: `resend_time` `status` `email` `phone` */
    get reqChangeUserPhone() {
      return url('/v1/user/phone/otp');
    },
    get cardActivation() {
      return url('/v1/user/cards/verification');
    },
    /** [POST] `LinkVerifyPayload` */
    get linkVerificationSend() {
      return url('/v1/user/linkverification');
    },
    /** [POST] `token` `tokenGenerator` */
    get linkVerificationCheck() {
      return url('/v1/user/linkverification/check');
    },
    /** [POST] `token` `tokenGenerator` `additionalData` */
    get linkVerificationApply() {
      return url('/v1/user/linkverification/apply');
    },
    /** [POST] `token` `tokenGenerator` `password` */
    get linkVerificationPassword() {
      return url('/v1/user/linkverification/set_password');
    },
    /** [POST] `username` */
    get reqSetPassword() {
      return url('/v1/user/set_password/otp/send');
    },
    /** [POST] `token` `username` */
    get checkSetPassword() {
      return url('/v1/user/set_password/otp/check');
    },
    /** [POST] `password` `token` `username` */
    get commitSetPassword() {
      return url('/v1/user/set_password/otp/apply');
    },
    get checkLinkSetPassword() {
      return url('/v1/user/set_password/link/check');
    },
    get commitLinkSetPassword() {
      return url('/v1/user/set_password/link/apply');
    },
    get deleteUserAccount() {
      return url('/v1/user/delete');
    },
    get getUserToken() {
      return url('/v1/auth/token', {
        proxy: { format: ['client_id', 'client_secret'] },
      });
    },
    get authorizeClient() {
      return url('/v1/auth/authorize');
    },
    get authorizeLoggedUser() {
      return url('/v1/auth/authorize_logged_in');
    },
    get validateClient() {
      return url('/v1/auth/authorize/validation');
    },
    get getSocialLoginCode() {
      return url('/v1/social_login/code');
    },
    get getSocialUserToken() {
      return url('/v1/social_login/token', {
        proxy: { format: ['clientId', 'clientSecret'] },
      });
    },
    get socialUserRegister() {
      return url('/v1/social_login/register');
    },
    get sendVerificationCode() {
      return url('/v1/verification/send');
    },
    get checkVerificationCode() {
      return url('/v1/verification/check');
    },
    get sendResetPasswordCode() {
      return url('/v1/forgot_password/send');
    },
    get checkResetPasswordCode() {
      return url('/v1/forgot_password/check');
    },
    get commitResetPassword() {
      return url('/v1/forgot_password/apply');
    },
    get checkLinkForgotPassword() {
      return url('/v1/forgot_password/link/check');
    },
    get commitLinkResetPassword() {
      return url('/v1/forgot_password/link/apply');
    },
    get locationProvinceList() {
      return url('/v1/location/province');
    },
    /** [GET] Params: `province_id` */
    get locationCityList() {
      return url('/v1/location/city');
    },
    /** [GET] Params: `city_id` */
    get locationDistrictList() {
      return url('/v1/location/district');
    },
    /** [GET] Params: `district_id` */
    get locationZipcodeList() {
      return url('/v1/location/zipcode');
    },
    get getProfessionList() {
      return url('/v1/user/profession');
    },
    get cardList() {
      return url('/v1/card-types');
    },
    get outlet() {
      return url('/v1/outlet');
    },
    get getUserValuePoint() {
      return url('/v1/point');
    },
    /** [GET] */
    get pointExpiry() {
      return url('/v1/point/expired');
    },
    /** [GET] Params: `page` `limit` `business_unit` `mutation` `from` `to` */
    get pointTransaction() {
      return url('/v1/point/transaction');
    },
    /** [GET] */
    get pointTransactionFilter() {
      return url('/v1/point/transaction/filter');
    },
    get clientMappingCheck() {
      return url('/v1/client_mapping/check');
    },
    get clientMappingConnect() {
      return url('/v1/client_mapping/connect');
    },
    get accountCompletionUse() {
      return url('/v1/accountcompletion/use');
    },
    get accountCompletionDetail() {
      return url('/v1/accountcompletion/detail');
    },
  },
  v2: {
    get checkClientSession() {
      return url('/v2/web/session');
    },
    get getClientData() {
      return url('/v2/web/client');
    },
  },
  v3: {
    get validateWebClient() {
      return url('/v3/web/client');
    },
  },
};

export default SSO;
