import { UAParser } from 'ua-parser-js';

export function parsePhone(phone: string, code = '62') {
  phone = phone.replace(/(\s|-|\(|\))/g, '');
  if (isNaN(+phone.replace('+', ''))) return false;
  if (phone.startsWith('0')) return code + phone.slice(1);
  if (phone.startsWith(code)) return '+' + phone;
  if (phone.startsWith('+')) return phone;
  return '+' + code + phone;
}

export const trimUserName = (first: string, last = '') =>
  `${first} ${last}`.trim().replace(/\b\w/g, (l) => l.toUpperCase());

export const isMyValue = (id: string) =>
  ['ValueID', 'MyValueWebsite', 'MyValueWeb', 'MyValueThirdParty', 'WifiMikrotik'].includes(id);
export const isPhone = (phone: string) => {
  phone = phone.replace(/(\s|-|^\+|\(|\))/g, '');
  if (!/^\d+$/.test(phone)) return 'Invalid Phone Number';
  if (phone.length < 9 || phone.length > 15) return 'Phone number must be between 9 and 15 digits';
  return true;
};
export const isEmail = (email = '') => /\S+@\S+\.\S+/.test(email) && email;
export const noEmpty = (msg: string) => (v: string) => !!v || msg;
export const noEmoji = (msg: string) => (v: string) => !/[\uD800-\uDBFF][\uDC00-\uDFFF]/.test(v) || msg;
export const passCheck = (msg: string) => (v: string) => (!!v && v.length >= 8) || msg;

export const AUTH = {
  accessTokenKey: 'access-token' as const,
  refreshTokenKey: 'refresh-token' as const,
};

export function isOldBrowser(ua: string) {
  const UA = new UAParser(ua);
  const browser = UA.getBrowser();
  const version = parseFloat(browser.version ?? '0');
  switch (browser.name) {
    case 'IE':
      return version < 12;
    case 'Safari':
    case 'Mobile Safari':
    case '[Mobile] Safari':
      return version < 14;
    case 'Chrome':
    case 'Chromium':
    case 'Chrome WebView':
      return version < 86;
    case 'Firefox':
      return version < 102;
    case 'Edge':
      return version < 107;
    default:
      return true;
  }
}
