<script setup lang="ts">
import type { NuxtError } from 'nuxt/app';
import VectorImage from '~/assets/images/myvalue_unknown.svg';

const { error } = defineProps<{ error: NuxtError }>();
const notFound = computed(() => error.statusCode === 404);

const auth = useAuth();
const route = useRoute();
const { $sentry } = useNuxtApp();

onMounted(() => {
  if (error.statusCode < 500) return;
  $sentry.withScope((scope) =>
    auth
      .sentryLogUser(scope)
      .setTransactionName(`[FE] (${route.path}) ${error.message}`)
      .captureException(error)
  );
});
</script>

<template>
  <div class="d-flex align-center justify-center" style="height: 100dvh">
    <div class="d-flex flex-column align-center ga-12 pa-4 pa-md-0">
      <img :src="VectorImage" alt="" />
      <div class="text-center">
        <h1 class="mb-2">{{ notFound ? 'Halaman Tidak Ditemukan' : 'Terjadi Kesalahan' }}</h1>
        <p>
          {{ notFound ? 'Mohon maaf halaman yang sedang kamu tuju tidak ditemukan' : error?.message }}
        </p>
      </div>
    </div>
  </div>
</template>
