import type Sentry from '@sentry/vue';
import type { DetailedUserProfile as UserProfile } from '~/types/user';
import type { ClientInfo, SSOAuthorization, SSOLoginRes } from '~/types/auth';

import { withQuery } from 'ufo';

import logoImage from '~/assets/images/myvalue_logo.png';
import backgroundImage from '~/assets/images/auth_bg.webp';

const WIFI = 'WifiMikrotik' as const;

export const useAuth = defineStore('auth', {
  state: () => ({
    user: null as UserProfile | null,
    tokens: null as TokenData | null,
    client: null as ClientInfo | null,
  }),
  getters: {
    verified: (s) => (s.user?.emailVerified || s.user?.phoneVerified) ?? false,
    fullName: (s) => (!s.user ? '' : trimUserName(s.user.firstName, s.user.lastName)),
    uiConfig: (s) => {
      const buttonStyle = 'FULL_ROUNDED' as const;
      return s.client?.uiConfig ?? ({ logoImage, backgroundImage, buttonStyle } as UIConfig);
    },
    isWIFI: (s) => {
      const clientId = s.client?.clientID;
      if (!clientId) return false;
      return clientId === WIFI || clientId.includes('wifi');
    },
    isPartner: (s) => {
      const clientId = s.client?.clientID;
      return Boolean(clientId) && clientId !== WIFI && !/(myvalue|^valueid$)/i.test(clientId!);
    },
  },
  actions: {
    async fetch(token: string) {
      if (this.user) return this.user;

      const { data, error } = await unwrapPromise(
        $fetch<UserProfile>(SSO.v1.getUserData, { headers: { Authorization: `Bearer ${token}` } })
      );

      if (error) {
        if (error.errorCode < 500) return null;
        throw showError({ fatal: true, statusCode: 500, statusMessage: error?.detail });
      }

      this.tokens = { at: token };
      return (this.user = data);
    },

    async check(username: string) {
      const { data, error } = await unwrapPromise(
        $fetch<{ exist: boolean }>(SSO.v1.checkIfUserExist, { body: { username }, method: 'POST' })
      );
      if (error) return { exist: false, error: error?.detail };
      return { exist: data.exist, error: null };
    },

    async login(username: string, password: string) {
      const gtm = useGTM();

      const { data, error } = await unwrapPromise(
        $fetch<SSOLoginRes>(SSO.v1.getUserToken, {
          method: 'POST',
          body: { username, password, grant_type: 'password' },
        })
      );

      if (error) return { user: null, error };

      gtm.clickLogin('loginNew');
      const user = await this.fetch(data.access_token);
      return { user, error: null };
    },

    async socialLogin(socialType: 'google', socialAccessToken: string) {
      const body = { socialType, socialAccessToken };
      const promise = $fetch<SSOLoginRes>(SSO.v1.getSocialUserToken, { method: 'POST', body });

      const { data, error } = await unwrapPromise(promise);
      if (error) return { user: null, error };

      const { data: codeRes, error: codeErr } = await unwrapPromise(
        $fetch<{ code: string }>(SSO.v1.getSocialLoginCode, {
          method: 'POST',
          body: { ...data, ...body },
          query: { client_id: this.client?.clientID },
        })
      );

      if (codeErr) return { user: null, error: codeErr };
      const code = codeRes.code;

      const user = await this.fetch(data.access_token);
      if (user) await new Promise((r) => setTimeout(r, 300));

      this.updateClient({ code });
      return { data: { user, code }, error: null };
    },

    async refreshToken() {
      const config = useRuntimeConfig();
      const refresh = useCookie<SessionToken | null>(AUTH.refreshTokenKey);

      if (!refresh.value || refresh.value.exp < Date.now()) {
        refresh.value = null;
        const error = { errorCode: 401, detail: 'Refresh Token Invalid or already expired' } as APIErrorData;
        return { error, data: undefined };
      }

      return await unwrapPromise(
        $fetch<SSOLoginRes>(SSO.v1.getUserToken, {
          method: 'POST',
          body: {
            grant_type: 'refresh_token',
            client_id: config.public.ssoClientId,
            refresh_token: refresh.value.token,
          },
        })
      );
    },

    async authorizeClient(username: string, password: string) {
      if (!this.client) throw createError({ statusCode: 400, statusMessage: 'Client not found' });

      const { data, error } = await unwrapPromise(
        $fetch<SSOAuthorization>(SSO.v1.authorizeClient, {
          method: 'POST',
          body: {
            username,
            password,
            state: this.client.state,
            client_id: this.client.clientID,
            redirect_uri: this.client.redirectURI,
          },
        })
      );

      if (error) return { data: null, error };
      this.updateClient({ code: data.code });
      return { data, error: null };
    },

    async refreshUserData(): Promise<UserProfile> {
      const data = await statefulFetch<UserProfile>(SSO.v1.getUserData);
      this.user = data;
      return data;
    },

    async logout(opts?: Parameters<typeof navigateTo>[1]) {
      await $fetch('/webapi/sso/logout', { method: 'DELETE' });
      return navigateTo('/authorize/form/login', opts);
    },

    async reset() {
      const access = useCookie(AUTH.accessTokenKey);
      const refresh = useCookie(AUTH.refreshTokenKey);

      this.user = null;
      access.value = null;
      refresh.value = null;
    },

    redirectUser(code?: string, state?: string) {
      if (!this.client) {
        navigateTo('/account', { replace: true });
        return;
      }
      if (this.client.mapping) {
        navigateTo('/authorize/mapping', { replace: true });
        return;
      }

      const sesRedir = sessionStorage.getItem('redirect');
      if (sesRedir) {
        navigateTo(sesRedir, { replace: true });
        sessionStorage.removeItem('redirect');
        return;
      }

      const url = withQuery(this.client.redirectURI, {
        code: code || this.client.code,
        state: state || this.client.state,
      });

      navigateTo(url, { external: true, replace: true });
      return url;
    },

    sentryLogUser(scope: Sentry.Scope) {
      if (!this.user) return scope;
      return scope.setUser({ id: this.user.kgValueID, email: this.user.email, phone: this.user.phone });
    },

    setUI(config: UIConfig) {
      const uiConfig = { ...this.client?.uiConfig, ...config };
      this.updateClient({ uiConfig });
    },

    updateClient(client: Partial<ClientInfo>) {
      if (!this.client) return;
      const _client = (this.client = { ...this.client, ...client });
      useCookie('client-data').value = JSON.stringify(_client);
    },
  },
});

type TokenData = {
  at: string;
  rt?: string;
};
